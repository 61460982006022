
import { defineComponent } from 'vue';
import gsap, { Power2 } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";
gsap.registerPlugin(CSSRulePlugin);

export default defineComponent({
  data(){ 
    return {
      timelines: {
        home_tl: {} as gsap.core.Timeline,
        about_tl: {} as gsap.core.Timeline,

        current_active: {} as gsap.core.Timeline
      },
      current_name: 'emilianomaccaferri',
      animating: true,
      main_img: ''
    }
  },
  async mounted(){
    
    const img = await fetch('img/hello.jpg');
    const blob = await img.blob();
    const url = URL.createObjectURL(blob);
    const home = document.getElementById('home')!;
    this.main_img = url;
    home.style.background = ` url(${this.main_img}) no-repeat center center scroll`
    home.style.backgroundSize = 'cover';

    document.getElementById('loading')!.style.opacity = "0";
    document.getElementById('loading')!.style.pointerEvents = "none";

    const home_tl = gsap.timeline({ paused: true });
    const about_tl = gsap.timeline({ paused: true });
    
    this.generateTimeline(home_tl, '.emilianomaccaferri.t');
    this.generateTimeline(about_tl, '.about.t');

    home_tl.play();
    this.timelines.home_tl = home_tl;
    this.timelines.about_tl = about_tl;
    this.timelines.current_active = home_tl;
    this.animating = false;
    
  },
  methods: {

    async click(name: string){
      
      if(this.current_name === name || this.animating) return;

      if(window.innerWidth > 900) gsap.to('.nopseudo', { duration: .25, opacity: 0, pointerEvents: 'none', transform: 'translateX(-50px) rotate(270deg)', ease: Power2.easeInOut })
      await this.timelines.current_active.reverse();
      
      switch(name){

        case 'emilianomaccaferri':
          document.getElementById('home')!.style.zIndex = "2";
          document.getElementById('about')!.style.zIndex = "1";
          document.body.style.overflow = 'hidden';
          await this.timelines.home_tl.play();
          this.timelines.current_active = this.timelines.home_tl;
          this.current_name = 'emilianomaccaferri';
        break;

        case 'about':
          document.getElementById('home')!.style.zIndex = "1";
          document.getElementById('about')!.style.zIndex = "2";
          await this.timelines.about_tl.play();
          document.body.style.overflow = 'auto';
          this.timelines.current_active = this.timelines.about_tl;
          this.current_name = 'about';
        break;

      }

      await gsap.to('.nopseudo', { duration: .25, opacity: 1, pointerEvents: 'all', transform: 'translateX(0) rotate(270deg)', ease: Power2.easeInOut })

    },
    generateTimeline(item: gsap.core.Timeline, selector: string){
      
      item.to(selector, {
        duration: .5,
        opacity: 1,
        transform: 'translateY(0)',
        ease: Power2.easeInOut
      });
      item.to(`.container${selector}`, {
        duration: .5,
        opacity: 1,
        zIndex: 3,
        transform: 'translateY(0)',
        ease: Power2.easeInOut
      }, 0)

    }
  }
})
